.section {
    width: 70vw;
    align-items: center;
    display: inline-flex;
    height: 100%;
    padding: 1.2rem;
    margin: 10vw;
}

.section-content {
    /* align-items: flex-start; */
    /* display: block; */
    text-align: left;
    width: 100%;
    /* padding: 0rem 3rem; */
    /* margin: 0 auto; */
    /* display: inline-flex; */
    /* justify-content: space-between; */
    /* align-items: center; */
}

@media (max-width: 1300px) {
    .section {
        width: calc(100% - 8vw);
        margin-left: 4vw;
        margin-right: 4vw;
    }
}