.video-area {
    align-items: center;
    width: 100%;
    box-shadow: 0px 0px 300px -100px white;
}

/* .video-responsive {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
} */

.content-header {
    font-family: Nahista;
    font-size: 4rem;
    color: white;
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  .lauderdale-desc-area {
    margin-top: 3rem;
  }

  .lauderdale-desc-header {
    font-family: Nahista;
    color: white;
    font-size: 4rem;
    text-decoration: underline;
  }

  .lauderdale-desc-text {
    color: white;
    font-size: 20px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }

@media (max-width: 815px) {
  .lauderdale-desc-header {
    font-size: 3em;
  }

  .lauderdale-desc-text {
    font-size: 16px;
  }
}

@media (max-width: 621px) {
  .lauderdale-desc-header {
    font-size: 2.5em;
  }

  .lauderdale-desc-text {
    font-size: 14px;
  }
}

@media (max-width: 525px) {
  .lauderdale-desc-header {
    font-size: 2em;
  }
}

@media (max-width: 428px) {
  .lauderdale-desc-header {
    font-size: 1.8em;
  }
}