/* .home-background {
    background-image: linear-gradient(#FFE58A, #FFCE61, #EE6C45, #BF3475, #50366F, #1F214D);
    width: 100%;
    height: 100%;
} */

.name-container {
    background: url(../Assets/coastal-city1.jpeg) no-repeat center center;
  background-size:100%;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.name {
    /* font-size: 18rem; */
    font-size: 20vw;
    font-family: Nahista;
    color: white;
    color: transparent;
    -webkit-text-stroke: 8px white;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    text-align: center;
}

.player-area {
    margin-top: 5rem;
    width: 100%;
}

.player {
    width: 60%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

.logo {
    height: 3rem;
    width: 3rem;
    filter:invert(1);
    margin: 1rem;
    cursor: pointer;
}

.section {
    margin-top: 4rem !important;
}


.home-content {
    display: flex;
    flex-direction: row;
    margin-bottom: 8rem;
}

.mrtime-picture {
    /* width: 25rem; */
    
    margin-left: auto;
    margin-right: auto;
    /* inset | offset-x | offset-y | blur-radius | spread-radius | color */
    box-shadow: 0px 0px 1000px 0px white;
    /* border: 2px solid black; */
    /* border-radius: 8px; */
    border-radius: 12px;
}

.album-title {
    font-size: 1.7rem;
    color: white;
    font-weight: 700;
    margin-right: auto;
    margin-left: auto;
    margin-top: 0.5rem;
}

.out-now {
    font-size: 1.2rem;
    color: white;
    font-weight: 700;
    margin-right: auto;
    margin-left: auto;
}

.home-column1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.home-column2 {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.home-spotify-player {
    border-radius: 12px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 0px 1000px 0px white;
    background-color: #204070;
    /* padding-left: 4rem; */
    /* padding-right: 4rem; */
}

.socials {
    display: flex;
    flex-direction: row;
    /* padding-left: 6rem; */
    /* padding-right: 6rem; */
    margin-top: 1rem;
    width: 80%;
    /* margin-left: auto;
    margin-right: auto;
    max-width: 400px; */
}

.social-icon {
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 1000px) {
    .home-content {
        flex-direction: column;
    }

    .home-column2 {
        margin-top: 2rem;
    }
}

@media (min-width: 501px) {
    .mrtime-picture {
        height: 24rem;
        width: 24rem;
    }

    .home-spotify-player {
        height: 24rem;
        width: 383px;
    }

    .socials {
        max-width: 400px;
    }
}

@media (max-width: 500px) {
    .mrtime-picture {
        /* height: 28rem; */
        /* width: 21rem; */
        width: 80%;
    }

    .home-spotify-player {
        width: 80%;
    }

    .album-title {
        font-size: 1.1rem;
    }

    .out-now {
        font-size: 1rem;
    }

    .socials {
        /* max-width: 200px; */
        /* margin-right:auto;
        margin-left:auto; */
    }
}

@media (min-width: 450px) {
    .socials{
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 449px) {
    .socials{
        margin-left: 7%;
    }
}