@import './Palette.css';

.navLink {  
    display: inline-flex;
    margin-right: 10px;
    font-size: 1.5em;
    float: right;
    color: white;
    text-decoration: none;
    font-weight: 400;
    padding-top: .4em;
    padding-bottom: .4em;
    padding-left: .4em;
    padding-right: .4em;

    font-weight: 100;
    font-family:SergioTrendy;

    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
    float: right;
}

.navLink:hover {
    color:lightgray;
    filter:grayscale(50);
    cursor: pointer;
}

.customNavBar {
    width: 100%;
    height: 2.5rem;
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5rem;
    justify-content: right;
    padding-left: 0;
}

.navline {
    /* background: linear-gradient(to left, white, var(--paletteColor3Dark)); */
    background: linear-gradient(to left, white, #F9993b);
    border: none;
    width: 80%;
    margin-right: 0;
    margin-left: auto;
    height: 2px !important;
    /* margin-top: -10px; */
    /* margin-top: 0px; */
    /* margin-bottom: 0.6rem; */
}

@media (max-width: 1000px) {
    .customNavBar {

    }
}

@media (max-width: 500px) {
    .navLink {
        padding-right: 0.1rem;
        padding-left: 0.1rem;
        font-size: 1.35em;
    }

    /* .navLink {
        font-size: 1rem;
        margin-top: 6px;
    } */
}