:root {
    --paletteWhite: #FFFFFF;
    --paletteColor1: #F8B195;
    --paletteColor2: #F67280;
    --paletteColor3: #C06C84;
    --paletteColor4: #6C5B7B;
    --paletteColor5: #355C7D;
    --paletteBlack: #000000;

    --paletteColor3Dark: #12416b;
    --paletteColor5Dark: #172633;
}