.tour-content {
    margin: 0 auto;
    align-items: center;
    width: 100%;
    margin-left: 0;
    color: white;
    font-family: "Nunito Sans";
}

.tour-table {
    width: 100%;
    font-size: 1.3rem;
}

.tour-table-header {
    font-family: Nahista;
    font-size: 3rem;
}

.tour-row-top {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* border-top: 1px solid white; */
    /* border-right: 1px solid white; */
    /* border-left: 1px solid white; */
    border-bottom: 1px solid white;
    border-radius: 4px 4px 0px 0px;
    /* padding: 1rem; */
    padding-bottom: 1rem;
}

.tour-row-mid {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* border-right: 1px solid white; */
    /* border-left: 1px solid white; */
    border-bottom: 1px solid white;
    /* padding: 1rem; */
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.tour-row-bottom {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* border-right: 1px solid white; */
    /* border-left: 1px solid white; */
    /* border-bottom: 1px solid white; */
    border-radius: 0px 0px 4px 4px;
    /* padding: 1rem; */
    padding-top: 1rem;
}

.tour-date {
    width: auto;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.tour-venue {
    width: 60%;
}

.tour-location {
    width: 20%;
    margin-left: auto;
    margin-right: 0;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 600;
    white-space: nowrap;
}

.ticket-button {
    /* background: #EFBF04; */
    background: #cca403;
    color: white;
    width: 6rem;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    font-weight: 700;
    border-radius: 4px;
    font-family: 'Courier New', Courier, monospace;
    margin-left: 1rem;
    transition: background-color 0.5s;
}

.ticket-button:hover {
    background: #eec933;
    color: white;
}

.tour-at {
    font-style: italic;
    margin-right: 4px;
    font-size: 1rem;
    color: white;
    text-decoration: none;
}

    .tour-at:hover{
        color: white;
    }

.tour-time {
    font-style: italic;
    font-weight: bold;
    font-size: 0.8rem;
    margin-right: 4px;
    color: white;
    text-decoration: none;
    white-space: nowrap;
}

    .tour-time:hover {
        color: white;
    }

@media (max-width: 1420px) {
    .tour-location {
        width: 23%;
    }

    .tour-table-header {
        font-size: 2.2rem;
    }
}

@media (max-width: 800px) {
    .tour-location {
        width: 24%;
    }
}

@media (max-width: 500px) {
    .tour-row-top {
        flex-direction: column;
    }

    .tour-row-mid {
        flex-direction: column;
    }

    .tour-row-bottom {
        flex-direction: column;
    }

    .tour-date {
        width: 100%;
    }

    .tour-location {
        margin-left: 0;
        margin-right: auto;
        width: 100%;
        font-weight: 600;
    }
}